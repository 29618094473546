import React, { useEffect, useState } from "react";
import { useStateValue } from "../StateProvider";
import { toastInfo } from "../shared/toastInfo";
//importing components
import { Picker } from "emoji-mart";
import TooltipCustom from "../shared/TooltipCustom";
import DrawerBottom from "./DrawerBottom";
//importing material-ui
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fab from "@material-ui/core/Fab";
import Hidden from "@material-ui/core/Hidden";
import Slide from "@material-ui/core/Slide";
import Tooltip from "@material-ui/core/Tooltip";
//importing material-ui-icons
import AttachFileOutlinedIcon from "@material-ui/icons/AttachFileOutlined";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import CloseIcon from "@material-ui/icons/Close";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";
import PersonIcon from "@material-ui/icons/Person";
import PhotoIcon from "@material-ui/icons/Photo";
import SendIcon from "@material-ui/icons/Send";
import VideoCallIcon from "@material-ui/icons/VideoCall";
//importing styles
import "emoji-mart/css/emoji-mart.css";
import "./ChatFooter.css";
// import { TwitterVideoEmbed } from "react-twitter-embed";

const attachFileLists = [
  {
    title: "Room",
    icon: <VideoCallIcon />,
    id: Math.random() * 100000,
  },
  {
    title: "Contact",
    icon: <PersonIcon />,
    id: Math.random() * 100000,
  },
  {
    title: "Document",
    icon: <InsertDriveFileIcon />,
    id: Math.random() * 100000,
  },
  {
    title: "Camera",
    icon: <CameraAltIcon />,
    id: Math.random() * 100000,
  },
  {
    title: "Photos & Videos",
    icon: <PhotoIcon />,
    id: Math.random() * 100000,
  },
];

function ChatFooter({ db, firebase, storage }) {
  const [{ user }] = useStateValue();
  const [input, setInput] = useState("");
  const [emoji, setEmoji] = useState(false);
  const [fileImageUrl, setFileImageUrl] = useState(null);
  const [fileVideoUrl, setFileVideoUrl] = useState(null);
  const [drawerBottom, setDrawerBottom] = useState(false);
  const [showAttachFile, setShowAttachFile] = useState(false);
  const [link, setLink] = useState("");
  const [extra, setExtra] = useState("");

  const addData = async (link) => {
    if (link) {
      await db
        .collection("messages")
        .add({
          message: "",
          extra: extra,
          video: link,
          name: user.name,
          uid: user.uid,
          photoURL: user.photoUrl,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function (docRef) {
          db.collection("messages").doc(docRef.id).set(
            {
              id: docRef.id,
            },
            { merge: true }
          );
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
    setInput("");
  };

  useEffect(() => {
    addData(link);
  }, [link, extra]);

  const checkLink = (regex, input) => {
    let arr = input.split(" ");
    let check = false;
    if (arr.length > 1) {
      setExtra(input);
    }

    for (let i = 0; i < arr.length; i++) {
      if (regex.test(arr[i])) {
        check = true;
        setLink(arr[i]);
        break;
      }
    }
    return check;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    const youtubeLink =
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
    const facebookVideoLink =
      /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/;
    const vimeoLink =
      /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
    const soundcloudLink = /^https?:\/\/(soundcloud\.com|snd\.sc)\/(.*)$/;
    const dailymotionLink =
      /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/;
    const urlLink =
      /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    const twitterLink =
      /http(?:s)?:\/\/(?:www\.)?twitter\.com\/([a-zA-Z0-9_]+)/;

    if (
      checkLink(youtubeLink, input) ||
      checkLink(facebookVideoLink, input) ||
      checkLink(vimeoLink, input) ||
      checkLink(soundcloudLink, input) ||
      checkLink(dailymotionLink, input) ||
      checkLink(twitterLink, input)
    ) {
      addData(link);
      // if (link) {
      // db.collection("rooms")
      //   .doc(roomId)
      //   .collection("messages")
      //   .add({
      //     message: "",
      //     video: link,
      //
      //
      //     name: user.name,
      // uid: user.uid,
      // photoURL: user.photoUrl,
      //     timestamp: firebase.firestore.FieldValue.serverTimestamp(),
      //   })
      //   .then(function (docRef) {
      //     console.log("here");
      //     console.log(link);
      //     console.log(result);
      //     console.log("Document written with ID: ", docRef.id);
      //     db.collection("rooms")
      //       .doc(roomId)
      //       .collection("messages")
      //       .doc(docRef.id)
      //       .set(
      //         {
      //           id: docRef.id,
      //         },
      //         { merge: true }
      //       );
      //   })
      //   .catch(function (error) {
      //     console.error("Error adding document: ", error);
      //   });

      // console.log("YOUTUBE LINK VALID");
    } else if (urlLink.test(input)) {
      await db
        .collection("messages")
        .add({
          message: "",
          url: input,
          name: user.name,
          uid: user.uid,
          photoURL: user.photoUrl,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function (docRef) {
          console.log(link);

          console.log("Document written with ID: ", docRef.id);
          db.collection("messages").doc(docRef.id).set(
            {
              id: docRef.id,
            },
            { merge: true }
          );
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    } else if (/\S/.test(input)) {
      console.log("here");
      await db
        .collection("messages")
        .add({
          message: input,
          name: user.name,
          uid: user.uid,
          photoURL: user.photoUrl,
          timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then(function (docRef) {
          // console.log("Document written with ID: ", docRef.id);
          db.collection("messages").doc(docRef.id).set(
            {
              id: docRef.id,
            },
            { merge: true }
          );
        })
        .catch(function (error) {
          console.error("Error adding document: ", error);
        });
    }
    // }
    setInput("");
    setEmoji(false);
  };

  const attachFile = () => {
    const attachToastId = "attach";
    if (showAttachFile === false) {
      setShowAttachFile(true);
    } else {
      setShowAttachFile(false);
    }
    // console.log("attachFile click", attachToastId);
  };

  const addEmoji = (e) => {
    let emoji = e.native;
    setInput(input + emoji);
  };

  const handleEmoticons = () => {
    setEmoji(true);
  };

  const handleEmoticonsClose = () => {
    setEmoji(false);
  };

  const onFileChange = async (e) => {
    const fileSizeToastId = "fileSizeToastId";
    const file = e.target.files[0];
    if (file.size > 12 * 1024 * 1024) {
      toastInfo(
        "File should not exceed more than 12MB",
        fileSizeToastId,
        "top-center"
      );
    } else {
      const storageRef = storage.ref();
      if (file.type.match("image.*")) {
        const imagesRef = storageRef.child(`images/`);
        const fileRef = imagesRef.child(new Date().getTime() + " " + file.name);
        await fileRef.put(file);
        setFileImageUrl(await fileRef.getDownloadURL());
        console.log("uploading image", fileImageUrl);
      } else if (file.type.match("video.*")) {
        const videosRef = storageRef.child(`videos/`);
        const fileRef = videosRef.child(new Date().getTime() + " " + file.name);
        await fileRef.put(file);
        setFileVideoUrl(await fileRef.getDownloadURL());
        console.log("uploading video", fileVideoUrl);
      }
      setDrawerBottom(true);
    }
  };

  const handleClickAway = () => {
    setShowAttachFile(false);
  };

  return (
    <div className="chat__footer">
      <DrawerBottom
        drawerBottom={drawerBottom}
        setDrawerBottom={setDrawerBottom}
        fileVideoUrl={fileVideoUrl}
        fileImageUrl={fileImageUrl}
        setFileImageUrl={setFileImageUrl}
        setFileVideoUrl={setFileVideoUrl}
        db={db}
        firebase={firebase}
        storage={storage}
      />

      {emoji ? (
        <TooltipCustom
          name="Close"
          icon={<CloseIcon />}
          onClick={() => handleEmoticonsClose()}
        />
      ) : null}

      <TooltipCustom
        name="Emoji"
        icon={<InsertEmoticonIcon />}
        onClick={() => handleEmoticons()}
      />

      {emoji ? (
        <>
          <Hidden only={["xs"]}>
            <Picker onSelect={addEmoji} />
          </Hidden>
          <Hidden smUp>
            <ClickAwayListener onClickAway={handleEmoticonsClose}>
              <Picker onSelect={addEmoji} />
            </ClickAwayListener>
          </Hidden>
        </>
      ) : null}

      {user.role === "admin" ? (
        <div>
          <TooltipCustom
            name="Attach"
            icon={<AttachFileOutlinedIcon />}
            onClick={attachFile}
          />
          {showAttachFile ? (
            <ClickAwayListener onClickAway={handleClickAway}>
              <div className="chat__attachFile">
                {attachFileLists.map((attachFileList) => (
                  <Slide
                    key={attachFileList.id}
                    direction="up"
                    in={attachFile}
                    mountOnEnter
                    unmountOnExit
                  >
                    <Tooltip
                      title={
                        <span
                          style={{
                            fontSize: "14px",
                            padding: "8px 5px 8px 5px",
                          }}
                        >
                          {attachFileList.title}
                        </span>
                      }
                      placement="left"
                    >
                      <Fab aria-label="person">
                        <div className="chat__icon">
                          <label htmlFor="file-input">
                            {attachFileList.icon}
                          </label>
                          <input
                            id="file-input"
                            type="file"
                            onChange={onFileChange}
                            accept="image/*,video/mp4,video/3gpp,video/quicktime"
                          />
                        </div>
                      </Fab>
                    </Tooltip>
                  </Slide>
                ))}
              </div>
            </ClickAwayListener>
          ) : null}
        </div>
      ) : null}

      <form>
        <input
          required
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type a message"
          type="text"
        />
        <button onClick={sendMessage} type="submit">
          Send a message
        </button>
      </form>

      <TooltipCustom
        name="Send Message"
        icon={<SendIcon style={{ color: "#28d146" }} />}
        onClick={sendMessage}
      />
    </div>
  );
}

export default ChatFooter;

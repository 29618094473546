import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "./Login.css";
import { useStateValue } from "./StateProvider";
import { setUser } from "./actions/userAction";
import { auth } from "./firebase";

function Login() {
  const [{ user }, dispatch] = useStateValue();

  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const signIn = () => {
    auth
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        dispatch(
          setUser({
            name: "admin",
            uid: "admin",
            role: "admin",
            photoUrl:
              "https://shanghai-date.com/uploads/g/t/t/h/q2t34kjldqrqv0pl7ihh.png",
          })
        );
        history.push("/");
      })
      .catch((err) => setError("Invalid Login Credentials"));
  };

  return (
    <div className="login">
      <h2>Welcome back, Admin</h2>
      <input
        type="email"
        name="email"
        required
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Email"
      />
      <input
        type="password"
        name="password"
        required
        onChange={(e) => setPassword(e.target.value)}
        placeholder="Password"
      />
      {error ? <p>{error}</p> : null}
      <button onClick={signIn}>Log In</button>
    </div>
  );
}

export default Login;

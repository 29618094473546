import React, { useEffect, useState } from "react";
import { useStateValue } from "./StateProvider";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
//importing firebase
//importing actions
import { setUser } from "./actions/userAction";
//importing components
import Login from "./Login";
import Chat from "../src/Chat/Chat";
import { ToastContainer } from "react-toastify";
//importing material-ui
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
//importing styles
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import dtla_img from "./images/dtla.png";

function App() {
  const [{ user }, dispatch] = useStateValue();
  const [name, setName] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(setUser(null));
    setLoading(true);
  }, [dispatch]);

  return (
    <div className="app">
      {loading ? (
        <>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
          />

          <div className="app__body">
            <Router>
              <Switch>
                <Route exact path="/admin">
                  <Login />
                </Route>

                <Route exact path="/">
                  {!user ? (
                    <div className="enter_name_container">
                      <img
                        src={dtla_img}
                        alt="dtla_chat"
                        height={150}
                        style={{ marginBottom: "50px" }}
                      />

                      <h2>Enter a chat name</h2>
                      <input
                        type="text"
                        placeholder="Name"
                        name="name"
                        id="name"
                        required
                        onChange={(e) => setName(e.target.value)}
                      />

                      <button
                        onClick={() =>
                          name
                            ? dispatch(
                                setUser({
                                  name: name,
                                  uid: uuidv4(),
                                  role: "user",
                                  photoUrl: `https://api.dicebear.com/7.x/pixel-art/svg?seed=${name}`,
                                })
                              )
                            : null
                        }
                      >
                        Enter
                      </button>
                    </div>
                  ) : (
                    <Chat isRoomExist={true} />
                  )}
                </Route>

                <Route path="*">
                  <Redirect to="/" />
                </Route>
              </Switch>
            </Router>
          </div>
        </>
      ) : (
        <div className="app__loading">
          <div>
            <div className="app__loading_circular">
              <CircularProgress />
            </div>
            <div className="app__loading_linear">
              <LinearProgress />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default App;

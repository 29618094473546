import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCxj8eNHUzjQQ7n79ydD3w6Gc0jxICqTR0",
  authDomain: "chatapp-a17f0.firebaseapp.com",
  projectId: "chatapp-a17f0",
  storageBucket: "chatapp-a17f0.appspot.com",
  messagingSenderId: "75315123993",
  appId: "1:75315123993:web:a5615c420b53b4cdbb9788",
  measurementId: "G-MEASUREMENT_ID",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();

export { auth, provider, storage, firebase };
export default db;
